<template>
<div>
  <button @click="handeleCancel()" style="height: 50px;width: 100px;background-color: aqua;" >
  打印订单
</button>
  <button @click="addOrder()" style="height: 50px;width: 100px;background-color: blue;" >
    保存订单
  </button>
  <div id="printable" style="width: 900px;">
    <div class="list" style="float: left;height: 720px;">
      <div>
        <!--    要求显示是谁家的订单-->
        <h2>建平县宜家装潢材料有限公司 销货清单</h2>
  <!--      <h2>客户：  {{userName}}                       时间:{{getFmtTime()}}</h2>-->
        <table style="margin: auto">
          <tr>
            <th colspan="2" class="xxx">客户：{{userName}}</th>
            <th colspan="2" class="xxx">时间:{{getFmtTime()}}  （____________）</th>
            <th class="xxx"></th>
            <th class="xxx"></th>
          </tr>
          <tr>
            <th>型号</th>
            <th>片/件</th>
            <th>长度</th>
            <th>数量</th>
            <th>单价</th>
            <th>金额</th>
          </tr>
          <!--      订单的每一行-->
          <OrderItme v-for="p in Product" :key="p.id" :Product="p" :isPrint="isPrint"></OrderItme>
          <tr>
            <th>
              {{OtherText}}
            </th>
            <th></th>
            <th>收货人签字</th>
            <th></th>
            <th></th>
            <th>
              总金额：{{allAmount}}
            </th>
          </tr>
        </table>
        <!--有一个时间戳-->
        <!--  有一个总价-->
        <!--  有一个注释  用于标记其他信息-->
      </div>
    </div>
    <div class="list" style="bottom: 20px">
      <div>
        <!--    要求显示是谁家的订单-->
        <h2>建平县宜家装潢材料有限公司 销货清单</h2>
  <!--      <h2>客户：  {{userName}}                       时间:{{getFmtTime()}}</h2>-->
        <table style="margin: auto">
          <tr>
            <th colspan="2" class="xxx">客户：{{userName}}</th>
            <th colspan="2" class="xxx">时间:{{getFmtTime()}}  （____________）</th>
            <th class="xxx"></th>
            <th class="xxx"></th>
          </tr>
          <tr>
            <th>型号</th>
            <th>片/件</th>
            <th>长度</th>
            <th>数量</th>
            <th>单价</th>
            <th>金额</th>
          </tr>
          <!--      订单的每一行-->
          <OrderItme v-for="p in Product" :key="p.id" :Product="p" :isPrint="isPrint"></OrderItme>
          <tr>
            <th>
              {{OtherText}}
            </th>
            <th></th>
            <th></th>
            <th>收货人签字</th>
            <th></th>
            <th>
              总金额：{{allAmount}}
            </th>
          </tr>
        </table>
        <!--有一个时间戳-->
        <!--  有一个总价-->
        <!--  有一个打印按键-->
      </div>
    </div>
  </div>
</div>

</template>

<script>
import printJS from "print-js";
import dayjs from "dayjs";
import OrderItme from "@/components/OrderItme";
import axios from "axios";
import {nanoid} from "nanoid";

export default {
  name: "PrintList",
  props:["Product","OtherText","userName","changUrl"],
  components:{OrderItme},
  data(){
    return {
      text:"",
      allAmount:0,
      isPrint:"true",
      OrderSum:0,
      OrderSumOfTime:0,
    }
  },
  methods:{
    handeleCancel(){
      printJS({printable:"printable",type:"html",targetStyles:["*"],font_size:"15px"})
    },
    getFmtTime(){
      return dayjs(this.time).format('YYYY年MM月DD日')
    },
    getOrderSum(){
      axios({
        method:"get",
        url:this.changUrl+"/api/getOrderSum",
      }).then((respones) =>{
        this.OrderSum = respones.data;
      })
    },
    getOrderSumOfTime(){
      axios({
        method:"get",
        url:this.changUrl+"/api/getOrderSumOfTime",
      }).then((respones) =>{
        this.OrderSumOfTime = respones.data;
      })
    },
    addOrder(){
      if(confirm('确定保存吗？')){
        //通知App组件将对应的todo对象删除
        const order = {
          orderId:nanoid(10),
          orderTime:dayjs(this.time),
          orderData:JSON.stringify(this.Product),
          orderUserName:this.userName,
        }
        console.log(order)
        axios({
          method:"post",
          url:this.changUrl+"/api/addOrder",
          data:JSON.stringify(order),
          headers: {
            'Content-Type': 'application/json'
          },
        })
      }
    },
  },
  beforeCreate() {
    setTimeout(()=>{
      this.Product.forEach((item) => {
        //遍历prodAllPrice这个字段，并累加
        this.allAmount += (item.amount-0);
      })
      this.getOrderSum()
      this.getOrderSumOfTime()
    },200)
  },
}
</script>

<style scoped>
.list {
  width: 800px;
}
tr {
  width: 780px;
}
tr th {
  width: 150px
}
.xxx {
  border-bottom: black 4px solid;
}
</style>